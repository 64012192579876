"use client";
import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";

const FAQSection = ({ FAQs, heading, subHeading }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleAccordionChange = (idx) => {
    setSelectedIndex(idx);
  };

  return (
    <section id="home_faq" className="py-20">
      {/* Container */}
      <div className="mx-auto w-full px-5 md:px-10">
        {/* Component */}
        <div className="flex flex-col items-start lg:flex-row gap-10">
          <div className="flex-1 w-full h-full flex flex-col">
            <div className="max-w-3xl mb-8 md:mb-12 lg:mb-16">
              {/* Update to h2 for better heading structure */}
              <h2 className="text-blackCustom text-gray-700 font-semibold text-3xl md:text-5xl">
                {heading}
              </h2>
              <p className="mt-4 text-gray-500 text-sm sm:text-base">
                {subHeading}
              </p>
            </div>
            <div className="mb-6 h-full w-full overflow-auto bg-primaryColorWhiteShade p-8 rounded-md flex-grow">
              <div className="flex flex-row gap-4">
                <Image
                  src="https://res.cloudinary.com/dydv6uxzo/image/upload/v1725959697/Meherun/AboutUs/Confused-cartoon_nycflk.webp"
                  alt="FAQ Cartoon"
                  width={1000}
                  height={1000}
                  className="inline-block w-24 object-cover rounded-full"
                />
                <div className="flex flex-col gap-1.5">
                  <h3 className="text-xl text-gray-600 font-bold">
                    Still have questions?
                  </h3>
                  <p className="max-w-sm text-gray-500 text-sm sm:text-base">
                    Can’t find the answer you’re looking for? Please chat with
                    us.
                  </p>
                </div>
              </div>
              <div className="mb-6 mt-8 h-[0.5px] w-full bg-gray-300"></div>
              <Link href={"/contact_us"}>
                <button className="flex items-center mt-auto gap-3 text-white transition bg-primaryColor border-0 py-2 rounded-md px-6 focus:outline-none group active:scale-95">
                  Contact Us
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 ml-auto transition-transform duration-300 ease-in-out transform group-hover:translate-x-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </button>
              </Link>
            </div>
          </div>

          <div className="flex-1 w-full space-y-2 flex flex-col">
            {FAQs.map((faq, index) => (
              <div
                className={`collapse collapse-arrow rounded-none ${
                  selectedIndex === index
                    ? "border-primaryColorLight border-b"
                    : "border-b"
                }`}
                key={index}
              >
                <input
                  type="radio"
                  name="accordion"
                  id={`accordion-${index}`}
                  checked={selectedIndex === index}
                  onChange={() => handleAccordionChange(index)}
                />
                {/* Update FAQ questions to use h3 for correct heading structure */}
                <label
                  className={`collapse-title text-xl font-medium ${
                    selectedIndex === index && "text-primaryColor font-semibold"
                  }`}
                  htmlFor={`accordion-${index}`}
                >
                  {faq.question}
                </label>
                <div className="collapse-content">
                  <p className="text-gray-400 text-lg">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
